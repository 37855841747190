textarea {
  font-size: 1em;
  padding: 5px;
  width: 400px;
}

button {
  margin: 20px 10px 0 0;
  width: 130px;
}

button:disabled {
  opacity: 0.7;
}

.buttons {
  padding-left: 140px;
}
