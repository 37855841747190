.jumbotron h1 {
  border-bottom: 1px solid #ccc;
}

.jumbotron h1 a {
  margin: 0 10px 0 0;
}

.jumbotron h1 span {
  font-weight: 500;
}
