.results p {
  margin: 5px;
}

.value {
  font-weight: 500;
  margin-left: 10px;
}

.error {
  color: #a22a21;
}
