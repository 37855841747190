* {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.87);
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 1em;
  word-break: break-word;
}

.App {
  margin: 0 auto 0 auto;
  max-width: 60em;
  padding: 1em 1.5em 1.3em 1.5em;
}

a {
  color: #53b6ef;
  text-decoration: none; /* no underline */
}

p {
  color: #444;
  font-weight: 300;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 0 0 0.8em 0;
  padding: 0 0 0.2em 0;
}

h2 {
  color: #444;
  font-weight: 500;
}

section {
  border-bottom: 1px solid #eee;
  margin: 0 0 30px 0;
  padding: 0 0 20px 0;
}

button {
  font-weight: 400;
  background-color: #559542;
  border: none;
  border-radius: 2px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 0.8em;
  margin: 0 0 1em 0;
  padding: 0.5em 0.7em 0.6em 0.7em;
}

button.default {
  background-color: #e0e0e0;
  color: #444;
}
